/*  
  #26
  Das Haus
*/

import React from "react"
import { graphql } from "gatsby"

import { DasHausTempl } from "../../pageTemplates/das-haus"

const DasHaus = ({ data, location }) => (
  <DasHausTempl data={data} location={location} en />
)

export const query = graphql`
  query {
    strapi {
      dasHau {
        meta_en {
          title
          description
        }
      }
      dashaus(sort: "item:asc") {
        images {
          url
          caption
          alternativeText
          imageFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
                aspectRatio
              }
            }
          }
        }
        item
        moreInfo
        subtitle
        subtitle_en
        text
        text_en
        title
        title_en
        title_long
        title_long_en
      }
    }
  }
`

export default DasHaus
